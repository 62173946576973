import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import ContactForm from "./ContactForm";
import EventPlanner from "./EventPlanner";
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [contactFormData, setContactFormData] = useState(() => {
    const savedData = localStorage.getItem("contactFormData");
    return savedData ? JSON.parse(savedData) : null;
  });
  
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // State to track form submission
  
  useEffect(() => {
    if (contactFormData) {
      localStorage.setItem("contactFormData", JSON.stringify(contactFormData));
    }
  }, [contactFormData]);


  useEffect(() => {
    // Block forward navigation if form is not submitted
    if (location.pathname === "/event-planner" && !isFormSubmitted) {
      navigate("/");
    }
  }, [location, isFormSubmitted, navigate]);
  const handleContactFormSubmit = (formData) => {
    console.log("Form Data Received:", formData);
    setContactFormData(formData);
    setIsFormSubmitted(true); // Set form submission to true
    navigate("/event-planner");
  };
  useEffect(() => {
    // Reset form submission state when navigating away from the form page
    if (location.pathname !== "/event-planner") {
      setIsFormSubmitted(false);
    }
  }, [location]);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ContactForm
            onSubmit={handleContactFormSubmit}
            initialData={contactFormData}
          />
        }
      />
      <Route path="/event-planner" element={<EventPlanner contactData={contactFormData} />} />
    </Routes>
  );
}
export default App;
