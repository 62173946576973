import React from "react";
import { Grid } from "@mui/material";
import { CategoryButton } from "./StyledComponents"; 
const CategoryOthers = ({ handleCategoryClick, selectedCategory }) => {
  return (
    <Grid item xs={12} style={{ marginBottom: "1rem" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("1")}
          selected={selectedCategory === "1"}
        >
          Garland Centerpieces
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("2")}
          selected={selectedCategory === "2"}
        >
          Bud Vases
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("3")}
          selected={selectedCategory === "3"}
        >
          Candle Centerpieces
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("4")}
          selected={selectedCategory === "4"}
        >
          Corporate Stage Flowers
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("5")}
          selected={selectedCategory === "5"}
        >
          Corporate Flower Centerpieces
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("6")}
          selected={selectedCategory === "6"}
        >
          Shower or Social Engagement
        </CategoryButton>
      </div>
    </Grid>
  );
};
export default CategoryOthers;