import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { PopupButton } from "react-calendly";
import videoFile from "./videos/output.mp4";
import logoImage from "./logo.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { motion } from 'framer-motion';
import "slick-carousel/slick/slick-theme.css";
import useScrollToTop from './useScrollToTop';
import ProductComponent from "./ProductComponent";
import SearchBar from "./SearchBar";
import CategorySelectors from "./CategorySelectors";
import { getProductData } from "./ProductData";
import { generateBill } from "./billing";
import BillComponent from "./BillComponent";
import { FAST_API_URL} from "./apiConfig";
import { jsPDF } from "jspdf";
import './DynamicSlide.css'; // Make sure the path is correct
import { getCategory } from './getCategory';
import 'jspdf-autotable';
import {
  AnimatedBox,
  AnimatedTextField,
  AnimatedButton,
} from "./StyledComponents";
// npm install react-tooltip --legacy-peer-deps
const steps = [
  "Select your favorite items in each category.",
  "Click on 'Estimate Cost & Generate Moodboard' to view your floral event plan.",
];

function GuideStep({ guideStep }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ paddingTop: '20px', textAlign: 'center' }}
    >
      {steps[guideStep - 1]}
    </motion.div>
  );
}

function EventPlanner({ contactData }) {
  useScrollToTop();
  const [guideStep, setGuideStep] = useState(1);
  const [moodboardImageBlob, setMoodboardImageBlob] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [productQuantities, setProductQuantities] = useState({});
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const [total, setTotal] = useState(0); // Total as a single state
  const [generatedImages, setGeneratedImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("1");
  const [showResult, setShowResult] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const proposalRef = useRef(null);
  const [imageLoading, setImageLoading] = useState(false);
  // State for storing the generated image
  const productListRef = useRef(null);
  const handleClearSearch = () => {
      setSearchInput("");
  };
  const DynamicSlide = ({ message, date }) => {
    return (
      <div className="dynamicSlide">
        <img
          src={logoImage}
          alt="Logo"
          style={{ maxWidth: "200px", marginBottom: "20px" }}
        />
        <h2 style={{ fontSize: "30px", margin: "10px 0" }}>{message}</h2>
        <p style={{ fontSize: "20px", margin: "5px 0" }}>
          Date of event: {contactData.eventDate}
        </p>
      </div>
    );
  };
  const ImageSlider = ({ images }) => {
    const [autoplaySpeed, setAutoplaySpeed] = useState(10000);
    const slideSpeeds = [100, 3000, 10000]; // Speeds for each slide
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: autoplaySpeed,
      beforeChange: (current, next) => {
        setAutoplaySpeed(slideSpeeds[next]); // Set the speed for the next slide
      },
    };
    return (
      <Slider {...settings} style={{ borderRadius: "30px" }}>
        {/* Moodboard Image Slides */}
        {images.map((image, i) => (
          <div key={i} className="slide">
            <img
              src={image}
              alt={`Slide ${i + 1}`}
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        ))}
        {/* Dynamic Slide (now placed after moodboard images) */}
        <DynamicSlide message={temp} date={contactData.eventDate} />
        {/* Video Slide */}
        <div>
          <video
            width="100%"
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            style={{ maxWidth: "100%", height: "auto" }}
          >
            <source src={videoFile} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Slider>
    );
  };
  const scrollToProductList = () => {
    productListRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const handleSearchClick = () => {
    scrollToProductList(); // Scroll to the product list
  };
  const getAllProducts = () => {
    return Array.from({ length: productNames.length }, (_, i) => i + 1);
  };
  const getFilteredProducts = () => {
    // Filter by search input across all products
    return getAllProducts().filter((index) =>
      productNames[index - 1].toLowerCase().includes(searchInput)
    );
  };
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (showResult && proposalRef.current) {
      setTimeout(() => {
        proposalRef.current.scrollIntoView({ behavior: "smooth" });
      }, 200); // Delay to ensure the page has rendered
    }
  }, [showResult]);
  let eventType = contactData?.eventType;
  let temp = "others";
  if (eventType === "others") {
    temp = contactData?.otherEventType;
  } else {
    temp = contactData?.eventType;
  }
  const {
    productPrices,
    getCategoryFilteredProducts,
    productNames,
    productImages,
  } = getProductData(eventType, selectedCategory, getAllProducts);
  const filteredProductIndices = searchInput
    ? getFilteredProducts()
    : getCategoryFilteredProducts(selectedCategory);
  useEffect(() => {
    let totalAmount = Object.entries(productQuantities).reduce(
      (total, [key, quantity]) => {
        const index = parseInt(key.split("-")[1]) - 1;
        return total + quantity * productPrices[index];
      },
      0
    );
    setTotal(totalAmount);
  }, [productQuantities]);
  const handleProductQuantityChange = (key, event) => {
    const newQuantity = parseInt(event.target.value) || 0;
    setProductQuantities({ ...productQuantities, [key]: newQuantity });
  };
  const handleIncrementDecrement = (key, increment) => {
    setProductQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[key] || 0;
      return {
        ...prevQuantities,
        [key]: increment
          ? currentQuantity + 1
          : Math.max(currentQuantity - 1, 0),
      };
    });
  };
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSearchInput(""); // Clear the search input 
    setGuideStep((prevStep) => Math.min(prevStep + 1, steps.length)); // Increment guide step without exceeding steps length
  };
 const handleDownloadPdf = async () => {
    const pdf = new jsPDF('p', 'pt', 'a4');
    pdf.setFontSize(12);
    const logoUrl = require('./logo.png');
    const logoImage = await fetch(logoUrl);
    const logoBlob = await logoImage.blob();
    const logoUrlBlob = URL.createObjectURL(logoBlob);
    const logoWidth = 150;
    const logoHeight = 90;
    const pageWidth = pdf.internal.pageSize.getWidth();
    // Function to add logo to the current page
    const addLogoToPage = () => {
      const xLogo = (pageWidth - logoWidth) / 2;
      pdf.addImage(logoUrlBlob, 'PNG', xLogo, 10, logoWidth, logoHeight);
    };
    // Add logo to the first page
    addLogoToPage();
    let startY = logoHeight + 30; // Initial Y position after the logo
    const checkAndAddNewPage = (incrementY) => {
      if (startY + incrementY > pdf.internal.pageSize.getHeight()) {
        pdf.addPage();
        addLogoToPage(); // Add logo to the new page
        startY = logoHeight + 30; // Reset startY position after adding logo
      }
    };
    addLogoToPage();
      // Adding Text with Style
      const addStyledText = (text, y, isTitle = false) => {
        const fontSize = isTitle ? 14 : 12;
        pdf.setFontSize(fontSize);
        pdf.setTextColor(isTitle ? 40 : 70);
        // Calculate text width
        const textWidth = pdf.getTextWidth(text);
        // Get page width
        const pageWidth = pdf.internal.pageSize.getWidth();
        // Calculate x position for centered text
        const x = (pageWidth - textWidth) / 2;
        pdf.text(text, x, y);
    };
    // Add Event and Contact Details
    checkAndAddNewPage(20); 
    addStyledText(`${contactData?.name || ''}`, startY, true);
    startY += 20;
    checkAndAddNewPage(20);
    addStyledText(`${contactData?.eventType || ''}`, startY);
    startY += 20;
    checkAndAddNewPage(30);
    addStyledText(`${contactData?.eventDate || ''}`, startY);
    startY += 30;
  const selectedProductKeys = Object.keys(productQuantities).filter(key => productQuantities[key] > 0);
  if (selectedProductKeys.length) {
    // Check if we need to add a new page before adding the moodboard
    checkAndAddNewPage(20);
    //addStyledText("Product Images", 40, startY, true);
    startY += 30;
    const arrangeImagesInMoodboard = async (selectedProductKeys, pdf, pageWidth, labelEndY) => {
      const margin = 8;
      const pageHeight = pdf.internal.pageSize.getHeight();
      const availableHeight = pageHeight - labelEndY - margin;
      const availableWidth = pageWidth - (margin * 2);
      let numCols = Math.ceil(Math.sqrt(selectedProductKeys.length));
      let numRows = Math.ceil(selectedProductKeys.length / numCols);
      let imageWidth = (availableWidth / numCols) - margin;
      let imageHeight = (availableHeight / numRows) - margin;
      let uniformSize = Math.min(imageWidth, imageHeight);
      let currentX = margin;
      let currentY = labelEndY + margin;
      for (let i = 0; i < selectedProductKeys.length; i++) {
        if (i % numCols === 0) { // Start of a new row
          if (i !== 0) {
            currentY += uniformSize + margin;
          }
          // Center the images in the last row
          if (Math.ceil(selectedProductKeys.length / numCols) === numRows && i >= (numRows - 1) * numCols) {
            let imagesInLastRow = selectedProductKeys.length - i;
            let totalWidthLastRow = imagesInLastRow * uniformSize + (imagesInLastRow - 1) * margin;
            currentX = (pageWidth - totalWidthLastRow) / 2;
          } else {
            currentX = margin;
          }
        }
        const index = parseInt(selectedProductKeys[i].split('-')[1]) - 1;
        const imageUrl = productImages[index];
        const image = await loadImage(imageUrl);
        pdf.addImage(image, 'JPEG', currentX, currentY, uniformSize, uniformSize);
        currentX += uniformSize + margin;
      }
    
      return currentY + uniformSize;
    };
    const loadImage = async (url) => {
      const response = await fetch(url);
      const imageBlob = await response.blob();
      return new Image().src = URL.createObjectURL(imageBlob);
    };      
    startY = await arrangeImagesInMoodboard(selectedProductKeys, pdf, pageWidth, startY);
  } 


// Adding the generated image from the cached blob
if (moodboardImageBlob) {
  const image = new Image();
  image.src = URL.createObjectURL(moodboardImageBlob);
  await new Promise((resolve, reject) => {
    image.onload = resolve;
    image.onerror = reject;
  });
  const imgProps = pdf.getImageProperties(image);
  const pdfWidth = pageWidth - 80;
  const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  checkAndAddNewPage(pdfHeight + 50); // Image height + some padding
  const imageFormat = moodboardImageBlob.type.split('/')[1];
  pdf.addImage(image, imageFormat.toUpperCase(), 40, startY, pdfWidth, pdfHeight);
  startY += pdfHeight + 50;
} else {
  console.error("No cached moodboard image found.");
}
pdf.addPage();
  startY = 90;
  const bill = generateBill(
    productQuantities, productPrices, productNames, eventType
  );
  // Add Bill Details Table with Total column
  const tableColumn = ['Category', 'Product', 'Quantity', 'Price', 'Total'];
  const tableRows = [];
  let subtotal = 0;
  bill.items.forEach(item => {
      const categoryName = getCategory(item.productName);
      const itemIndex = productNames.findIndex(name => name === item.productName);
      const itemPrice = productPrices[itemIndex];
      const total = item.quantity * itemPrice;
      subtotal += total;
      const row = [
          categoryName,
          item.productName,
          item.quantity,
          `$${itemPrice.toFixed(2)}`,
          `$${total.toFixed(2)}`
      ];
      tableRows.push(row);
  });
  // Add Subtotal row at the end of the first table
  tableRows.push([
      '', 'Subtotal', '', '',
      `$${subtotal.toFixed(2)}`
  ]);
  pdf.autoTable(tableColumn, tableRows, { startY: startY });
  startY = pdf.autoTable.previous.finalY + 50; // Update startY for next section
      // Summary Section with Subtotal
      const summaryColumn = ['Description', '','','','','Amount']; // Modified column definitions
      const summaryRows = [];
      const formatNumber = (num) => num ? num.toFixed(2) : '0.00';
    // Adding empty strings ('') to fill the columns between 'Description' and 'Amount'
      summaryRows.push(['Subtotal', '', '', '', '', `$${formatNumber(subtotal)}`]);
      summaryRows.push(['Tax', '', '', '', '', `$${formatNumber(bill.tax)}`]);
      summaryRows.push(['Service Fee', '', '', '', '', `$${formatNumber(bill.serviceFee)}`]);
      summaryRows.push(['Additional Charges', '', '', '', '', `$${formatNumber(bill.additionalCharges)}`]);
      summaryRows.push(['Total', '', '', '', '', `$${formatNumber(bill.total)}`]);
      pdf.autoTable(summaryColumn, summaryRows, { startY: startY });
      // Download PDF
      startY = pdf.autoTable.previous.finalY + 30; // Update startY for the footnote
      pdf.setFontSize(10);
      pdf.setTextColor(100); // Set a lighter text color for the footnote
      const footnoteLines = [
        "Note: This document is only an estimate.",
        "For a detailed and clear invoice, please schedule a meeting with our florist or call us at your convenience.",
        "Schedule here: https://calendly.com/southsideblooms",
        "Call us: +1 773-358-4227"
    ];
    footnoteLines.forEach(line => {
      pdf.text(line, 40, startY, { maxWidth: pageWidth - 80 });
      startY += 15; // Adjust the line spacing
  });
    // Download PDF
    pdf.save('event-plan.pdf');
    URL.revokeObjectURL(logoUrlBlob); 
};

const handleSubmit = async () => {
  setIsSubmitting(true); // Disable the button as soon as it's clicked
  
  console.log("Submit started");
  setImageLoading(true);
  setShowResult(true);
  let topic = contactData.flowerTheme;
  if (topic === "others" && contactData.otherFlowerTheme) {
    topic = contactData.otherFlowerTheme;
  }
  let eventType1 = contactData?.eventType;
  let temp = "others";
  if (eventType1 === "others") {
    temp = contactData?.otherEventType;
  } else {
    temp = contactData?.eventType;
  }
  const contactDataString = encodeURIComponent(JSON.stringify(contactData));
  try {
    const myurl = `${FAST_API_URL}/generate-images/?contactData=${contactDataString}`;
    const response = await fetch(myurl);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json(); // Assuming the response is an array of image URLs
    setGeneratedImages(data);

    // Cache the first moodboard image blob if available
    if (data.length > 0) {
      const imageUrl = data[0].replace(/^http:/, 'https:');
      const imageResponse = await fetch(imageUrl);
      const imageBlob = await imageResponse.blob();
      setMoodboardImageBlob(imageBlob);
    }

    setTimeout(() => {
      proposalRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300);
  } catch (error) {
    console.error("Failed to fetch the image:", error);
  } finally {
    setImageLoading(false);
    proposalRef.current?.scrollIntoView({ behavior: "smooth" });
  }
};

  const formatBillDetailsForURL = (bill) => {
    // Function to format number to two decimal places
    const formatNumber = (num) =>
      num && !isNaN(num) ? num.toFixed(2) : "0.00";
    // Format each item detail
    const itemsString = bill.items
      .map((item) => {
        const categoryName = getCategory(item.productName); // Fetch category name
        return `Category: ${categoryName}, Product: ${item.productName}, Quantity: ${item.quantity}, Total: $${
          item.individualTotal && !isNaN(item.individualTotal)
            ? item.individualTotal.toFixed(2)
            : "0.00"
        }`;
      })
      .join("\n"); // Newline after each item
    // Format the summary of the bill
    const billSummary = `Subtotal: $${formatNumber(
      bill.subtotal
    )}, Tax: $${formatNumber(bill.tax)}, Service Fee: $${formatNumber(
      bill.serviceFee
    )}, Additional Charges: $${formatNumber(
      bill.additionalCharges
    )}, Total: $${formatNumber(bill.total)}`;
    return `Items: \n${itemsString}\nSummary: \n${billSummary}`;
  };
  const renderProposal = () => {
    const proposalStyle = {
      backgroundColor: "#ffffff",
      borderRadius: "20px",
      boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
      textAlign: "center",
      maxWidth: "600px", // Maximum width for larger screens
      width: "90%", // Relative width to adapt to smaller screens
      padding: "20px", // Fixed padding
      margin: "5% auto",
      display: "flex", // Using Flexbox for better control
      flexDirection: "column", // Stack children vertically
      alignItems: "center", // Center-align children
    };
    const priceStyle = {
      fontSize: "1.5rem",
      color: "#2e7d32",
      fontWeight: "600",
      marginBottom: "25px",
    };
    const thankYouStyle = {
      marginBottom: "30px",
      color: "#1565c0",
      fontWeight: "600",
      fontSize: "1.25rem",
    };
    const fullPageLoaderStyle = {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(236, 240, 241, 0.9)",
      zIndex: 1000,
    };
    const bill = generateBill(
      productQuantities,
      productPrices,
      productNames,
      productImages, // You might need to pass product images or categories
      selectedCategory, // Pass the selected category
      eventType,
    );
    const formattedBillDetails = formatBillDetailsForURL(bill);
    // Construct a1 parameter
    let topic = contactData.flowerTheme;
    if (topic === "others" && contactData.otherFlowerTheme) {
      topic = contactData.otherFlowerTheme;
    }
    const a1 = `Flower Theme : ${topic} \nEvent : ${temp}\nEvent Date: ${contactData.eventDate}\nSample Bill Details: \n${formattedBillDetails}`;
    // Construct the Calendly URL with the encoded 'a1' parameter
    const calendlyURL = `https://calendly.com/southsideblooms?name=${encodeURIComponent(
      contactData.name
    )}&email=${encodeURIComponent(
      contactData.email
    )}&phone=${encodeURIComponent(
      contactData.phoneNumber
    )}&a1=${encodeURIComponent(a1)}`;
    return (
      <>
      {imageLoading && (
        <div style={fullPageLoaderStyle}>
          <iframe 
            src="loadingAnimation.html" 
            title="Loading Animation" 
            style={{ width: '100%', height: '100%', border: 'none' }}
          ></iframe>
        </div>
      )}
      {!imageLoading && (
          <Box style={proposalStyle} ref={proposalRef}>
            <Typography variant="h6" style={thankYouStyle}>
              Thank You for Your Submission {contactData.name}!
            </Typography>
            <Typography variant="h6" style={priceStyle}>
              SubTotal: ${total}
            </Typography>
            <BillComponent
              id="bill-component"
              productQuantities={productQuantities}
              productPrices={productPrices}
              productNames={productNames}
              eventType={eventType}
              total={total} // Pass total as a prop
            />
          <Box id="slider-container" style={{ width: "100%", overflowX: "hidden" }}>
            {generatedImages.length > 0 && (
              <>
                <ImageSlider images={generatedImages} />
              </>
            )}
          </Box>
            <Typography variant="h6" style={thankYouStyle}>
              Best of luck!
            </Typography>
            <PopupButton
              url={calendlyURL}
              rootElement={document.getElementById("root")}
              text="Book a Consultation Now"
            />
        <AnimatedButton
          variant="contained"
          color="primary"
          size="small"
          fullWidth
          onClick={handleDownloadPdf}
          sx={{ fontWeight: "bold", marginTop: "20px" }}
        >
        Download Event Plan
      </AnimatedButton>
           </Box>
        )}
      </>
    );
  };
  return (
    <AnimatedBox
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.3 }}
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxWidth: "2000px",
        margin: "0 auto",
        padding: "1rem",
        border: "1px solid #64b5f6", // Border color
        borderRadius: "4px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#ffffff", // White background for the form
      }}
      noValidate
      autoComplete="off"
    >
    <GuideStep guideStep={guideStep} />
      {/* Logo Image at the top of the form */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem", // Spacing below the logo
        }}
      >
      <motion.img
        src={logoImage}
        alt="Logo"
        initial={{ x: -1000 }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', stiffness: 120 }}
        style={{ maxWidth: "250px", height: "auto" }}
      />
      </Box>
      <SearchBar
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleSearchClick={handleSearchClick}
        handleClearSearch={handleClearSearch}
      />
      <CategorySelectors
        eventType={eventType}
        handleCategoryClick={handleCategoryClick}
        selectedCategory={selectedCategory}
      />
      <Grid container spacing={5} ref={productListRef} style={{ 
          maxHeight: '450px', 
          overflowY: 'auto', 
          marginTop: '5px', 
          marginLeft: '20px',
          marginRight: '20px', // Adjust right margin
          width: 'calc(100% - 40px)' 
          // Adjust width
      }}>
      <ProductComponent
        productQuantities={productQuantities}
        handleProductQuantityChange={handleProductQuantityChange}
        handleIncrementDecrement={handleIncrementDecrement}
        productPrices={productPrices}
        productNames={productNames}
        productImages={productImages}
        filteredProductIndices={filteredProductIndices}
      />
      <Grid item xs={12}>
        <AnimatedTextField
          id="total"
          label="Total"
          variant="outlined"
          fullWidth
          value={total}
          disabled
        />
      </Grid>
      </Grid>
      <Grid item xs={12}></Grid>
      {showResult && renderProposal()}
      {!isSubmitting && (
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "#fff",
            padding: "10px 0",
          }}
        >
          <AnimatedButton
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={handleSubmit}
            sx={{ fontWeight: "bold" }}
          >
            Estimate Cost And Generate Moodboard
          </AnimatedButton>
        </Box>
      )}
    </AnimatedBox>
  );
}
export default EventPlanner;



/*
Steps :
1) firebase init
2) change the firebase.json
3) npm run build
4) firebase deploy
*/