import { mainList2 } from './mainList2';
export function getCategory(productName) {
    // Remove " per feet" from the end of productName if it exists
    if (productName.endsWith(" per 10 feet")) {
        productName = productName.slice(0, -12);
    }
    // Remove the last six characters of the productName if it has enough length
    const modifiedProductName = productName.length > 6 ? productName.slice(0, -6) : '';
    const normalizedProductName = modifiedProductName.trim().toLowerCase().replace(/[\s_]+/g, ' ');
    for (const imagePath of mainList2) {
        const segments = imagePath.split('/');
        const fileName = segments[segments.length - 1];
        // Remove file extension and numbers, and normalize
        const nameWithoutExtension = fileName.split('.')[0].toLowerCase().replace(/[\s_0-9]+/g, ' ');
        // Check if the normalized product name is a part of the file name
        if (nameWithoutExtension.includes(normalizedProductName)) {
            return segments[segments.length - 2];
        }
    }
    return 'Product';
}
