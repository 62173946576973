/*
import { Typography } from "@mui/material";
import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
const BillComponent = ({
  productQuantities,
  productPrices,
  productNames,
  eventType,
}) => {
  const { generateBill } = require("./billing");
  const renderBillItems = () => {
    const bill = generateBill(
      productQuantities,
      productPrices,
      productNames,
      eventType
    );
    const nonZeroQuantityItems = bill.items.filter((item) => item.quantity > 0);
    const subtotal =
      bill.total - bill.tax - bill.serviceFee - bill.additionalCharges;
    return (
      <Box style={{ padding: "1rem" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#e0e0e0", textAlign: "left" }}>
              <th style={{ padding: "8px" }}>Product Name</th>
              <th style={{ padding: "8px", textAlign: "right" }}>Quantity</th>
              <th style={{ padding: "8px", textAlign: "right" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {nonZeroQuantityItems.map((item, index) => (
              <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                <td style={{ padding: "8px" }}>{item.productName}</td>
                <td style={{ padding: "8px", textAlign: "right" }}>
                  {item.quantity}
                </td>
                <td style={{ padding: "8px", textAlign: "right" }}>
                  ${item.individualTotal.toFixed(2)}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="2" style={{ padding: "8px", textAlign: "right" }}>
                Subtotal:
              </td>
              <td style={{ padding: "8px", textAlign: "right" }}>
                ${subtotal.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{ padding: "8px", textAlign: "right" }}>
                Tax (10.25%) :
              </td>
              <td style={{ padding: "8px", textAlign: "right" }}>
                ${bill.tax.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{ padding: "8px", textAlign: "right" }}>
                Service Fee ({eventType === "event" ? "15%" : "10%"}){" "}
                {bill.additionalCharges > 0
                  ? `+ $${bill.additionalCharges.toFixed(2)}`
                  : ""}
                :
              </td>
              <td style={{ padding: "8px", textAlign: "right" }}>
                ${(bill.serviceFee + bill.additionalCharges).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td
                colSpan="2"
                style={{
                  padding: "8px",
                  textAlign: "right",
                  borderTop: "1px solid #ddd",
                  fontWeight: "bold",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  padding: "8px",
                  textAlign: "right",
                  borderTop: "1px solid #ddd",
                }}
              >
                ${bill.total.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    );
  };
  return (
    <Accordion
      style={{
        marginBottom: "20px",
        backgroundColor: "#f5f5f5",
        borderRadius: "4px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <AccordionSummary
        style={{
          backgroundColor: "#64b5f6",
          color: "white",
        }}
      >
        <Typography
          style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
        >
          View Detailed Bill
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{renderBillItems()}</AccordionDetails>
    </Accordion>
  );
};
export default BillComponent;
*/
import { Typography } from "@mui/material";
import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { getCategory } from './getCategory'; // Importing getCategory function
const BillComponent = ({
  productQuantities,
  productPrices,
  productNames,
  eventType,
}) => {
  const { generateBill } = require("./billing");
  const renderBillItems = () => {
    const bill = generateBill(
      productQuantities,
      productPrices,
      productNames,
      eventType
    );
    const nonZeroQuantityItems = bill.items.filter((item) => item.quantity > 0);
    const subtotal =
      bill.total - bill.tax - bill.serviceFee - bill.additionalCharges;
    return (
      <Box style={{ padding: "1rem" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#e0e0e0", textAlign: "left" }}>
              <th style={{ padding: "8px" }}>Category</th>
              <th style={{ padding: "8px" }}>Product Name</th>
              <th style={{ padding: "8px", textAlign: "right" }}>Quantity</th>
              <th style={{ padding: "8px", textAlign: "right" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {nonZeroQuantityItems.map((item, index) => (
              <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                <td style={{ padding: "8px" }}>{getCategory(item.productName)}</td>
                <td style={{ padding: "8px" }}>{item.productName}</td>
                <td style={{ padding: "8px", textAlign: "right" }}>
                  {item.quantity}
                </td>
                <td style={{ padding: "8px", textAlign: "right" }}>
                  ${item.individualTotal.toFixed(2)}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="3" style={{ padding: "8px", textAlign: "right" }}>
                Subtotal:
              </td>
              <td style={{ padding: "8px", textAlign: "right" }}>
                ${subtotal.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colSpan="3" style={{ padding: "8px", textAlign: "right" }}>
                Tax (10.25%) :
              </td>
              <td style={{ padding: "8px", textAlign: "right" }}>
                ${bill.tax.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colSpan="3" style={{ padding: "8px", textAlign: "right" }}>
                Service Fee ({eventType === "event" ? "15%" : "10%"}){" "}
                {bill.additionalCharges > 0
                  ? `+ $${bill.additionalCharges.toFixed(2)}`
                  : ""}
                :
              </td>
              <td style={{ padding: "8px", textAlign: "right" }}>
                ${(bill.serviceFee + bill.additionalCharges).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td
                colSpan="3"
                style={{
                  padding: "8px",
                  textAlign: "right",
                  borderTop: "1px solid #ddd",
                  fontWeight: "bold",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  padding: "8px",
                  textAlign: "right",
                  borderTop: "1px solid #ddd",
                }}
              >
                ${bill.total.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    );
  };
  return (
    <Accordion
      style={{
        marginBottom: "20px",
        backgroundColor: "#f5f5f5",
        borderRadius: "4px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <AccordionSummary
        style={{
          backgroundColor: "#64b5f6",
          color: "white",
        }}
      >
        <Typography
          style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
        >
          View Detailed Estimate
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{renderBillItems()}</AccordionDetails>
    </Accordion>
  );
};
export default BillComponent;