import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import {
  AnimatedProductBox,
  SmallButton,
  TotalLabel,
} from "./StyledComponents";
function ProductComponent({
  productQuantities,
  handleProductQuantityChange,
  handleIncrementDecrement,
  productPrices,
  productNames,
  productImages,
  filteredProductIndices,
}) {
  return (
    <Grid container spacing={5}>
      {filteredProductIndices.map((index) => {
        const key = `product-${index}`;
        const quantity = productQuantities[key] || 0;
        const individualTotal = quantity * productPrices[index - 1];
        const productImage = productImages[index - 1];
        return (
          <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
            <AnimatedProductBox sx={{ margin: "0rem" }}>
              <img
                src={productImage}
                alt={`Product ${index}`}
                style={{ width: "80%", height: "auto" }}
              />
              <Typography variant="subtitle1" style={{ marginTop: "0.5rem" }}>
                {productNames[index - 1]}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <SmallButton
                  variant="outlined"
                  size="large"
                  onClick={() => handleIncrementDecrement(key, false)}
                >
                  -
                </SmallButton>
                <TextField
                  id={key}
                  variant="outlined"
                  fullWidth
                  value={quantity}
                  onChange={(event) => handleProductQuantityChange(key, event)}
                  style={{ margin: "0 10px", textAlign: "center" }}
                  InputProps={{
                    style: {
                      textAlign: "center",
                      fontWeight: "bold",
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: "center",
                      padding: "10px 14px",
                    },
                  }}
                />
                <SmallButton
                  variant="outlined"
                  size="large"
                  onClick={() => handleIncrementDecrement(key, true)}
                >
                  +
                </SmallButton>
              </div>
              <TotalLabel> ${individualTotal}</TotalLabel>
            </AnimatedProductBox>
          </Grid>
        );
      })}
    </Grid>
  );
}
export default ProductComponent;