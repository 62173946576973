import React from "react";
import CategoryWedding from "./CategoryWedding";
import CategoryCorporate from "./CategoryCorporate";
import CategoryOthers from "./CategoryOthers";
const CategorySelectors = ({
  eventType,
  handleCategoryClick,
  selectedCategory,
}) => {
  switch (eventType) {
    case "wedding":
      return (
        <CategoryWedding
          handleCategoryClick={handleCategoryClick}
          selectedCategory={selectedCategory}
        />
      );
    case "corporate":
      return (
        <CategoryCorporate
          handleCategoryClick={handleCategoryClick}
          selectedCategory={selectedCategory}
        />
      );
    case "others":
      return (
        <CategoryOthers
          handleCategoryClick={handleCategoryClick}
          selectedCategory={selectedCategory}
        />
      );
    default:
      return null;
  }
};
export default CategorySelectors;