import { styled } from "@mui/system";
import { Paper, Button, Box, TextField } from "@mui/material";

export const ProposalContainer = styled(Paper)`
  background-color: #e3f2fd; // Light blue background
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
export const CategoryButton = styled(Button)`
  background-color: ${({ selected }) =>
    selected ? "#5c24bd" : "#f0f0f0"}; 
  color: ${({ selected }) => (selected ? "white" : "black")};
  &:hover {
    background-color: ${({ selected }) =>
      selected
        ? "#5021a7"
        : "#e0e0e0"}; 
  }
`;
export const ProductQuantityField = styled("div")`
  display: flex;
  align-items: center; 
  justify-content: center; 
  margin: 5px;
`;
export const TotalLabel = styled("div")`
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8em; 
  color: 5c24bd; 
`;
export const AnimatedBox = styled(Box)`
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.3s forwards;
`;
export const AnimatedTextField = styled(TextField)`
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.3s forwards;

  & .MuiInputBase-input {
    text-align: center; 
    padding-top: 12px; 
    padding-bottom: 12px; 
    line-height: normal; 
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const AnimatedButton = styled(Button)`
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.3s forwards;
  background-color: #5c24bd; // Button color
  color: white; // Text color
  width: 100%; // Full width
  height: 150%; // Full height

  &:hover {
    background-color: #5021a7; 
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const ProductBox = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 0;
  width: 100%;
  height: auto;
  position: relative;
  border-radius: 10px; // Smoothen the corners
`;

export const AnimatedProductBox = styled(ProductBox)`
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.3s forwards;
  padding: 1.2rem 0;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
export const SmallButton = styled(Button)`
  padding: 0.8rem;
  width: 40px; // Set the width of the button
  height: 40px; // Set the height of the button
  min-width: unset;
  background-color: #5c24bd; 
  color: white; 
  font-size: 1.5rem;
  font-weight: bold; 
  &:hover {
    background-color: #5c24bd;
  }
`;
