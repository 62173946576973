import React from "react";
import { TextField, Button } from "@mui/material";
const SearchBar = ({
  searchInput,
  setSearchInput,
  handleSearchClick,
  handleClearSearch,
}) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
    >
      <TextField
        id="search-box"
        label="Search Product"
        variant="outlined"
        fullWidth
        value={searchInput}
        onChange={(event) => setSearchInput(event.target.value.toLowerCase())}
        style={{ marginRight: "0.5rem" }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearchClick}
        style={{ marginRight: "0.5rem" }}
      >
        Search
      </Button>
      <Button variant="outlined" color="secondary" onClick={handleClearSearch}>
        Clear
      </Button>
    </div>
  );
};
export default SearchBar;