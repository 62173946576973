import React from "react";
import {Grid} from "@mui/material";
import { CategoryButton } from "./StyledComponents"; 
const CategoryWedding = ({ handleCategoryClick, selectedCategory }) => {
  return (
    <Grid item xs={12} style={{ marginBottom: "1rem" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("1")}
          selected={selectedCategory === "1"}
        >
          Bridal Bouquets
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("2")}
          selected={selectedCategory === "2"}
        >
          Bridesmaids Bouquets
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("3")}
          selected={selectedCategory === "3"}
        >
          Cake Flowers
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("4")}
          selected={selectedCategory === "4"}
        >
          Candle Centerpieces
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("5")}
          selected={selectedCategory === "5"}
        >
          Ceremony Flowers
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("6")}
          selected={selectedCategory === "6"}
        >
          Flower Corsage
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("7")}
          selected={selectedCategory === "7"}
        >
          Flower Crowns
        </CategoryButton>
        <CategoryButton
          variant="contained"
          onClick={() => handleCategoryClick("8")}
          selected={selectedCategory === "8"}
        >
          Boutonnieres
        </CategoryButton>
      </div>
    </Grid>
  );
};
export default CategoryWedding;